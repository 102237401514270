// This file defines all scss variables for the Librex Portal frontend
// Import this file into other scss files for access to all variables, functions, mixins, and utilities.
//
// Variables must be defined *prior* to the bootstrap variables import as bootstrap uses the !default css flag
// Maps can be modified *after* importing the variables, maps, and mixins

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";
/*
 * LIBREX COLORS
 */

// primary
$purpleBright: #6100FF;
$mediumPurple: #530aae;
$darkPurple: #1b1464;

// secondary
$blueBright: rgb(0, 125, 255);
$blueBright10a: rgba(0, 125, 255, 0.1);

// other colors
$red: rgb(235, 87, 87);
$orange: #f2994a;
$green: #00d09e;
$green2: #27ae60;
$greenBg: rgba(39, 174, 96, 0.2);
$yellow: #ffb800;

// shades
$gray1: #1c232b;
$gray2: #39414d;
$gray3: #7d899f;
$gray4: #bdc8da;
$gray5: #e2e9f0;
$gray6: #f3f5f9;
$gray7: #fafafa;
$white: #ffffff;

// idk wtf these are but they're in figma
$buttonStroke: rgba(186, 195, 209, 0.5);

// gradients
$purpleToLightBlue: linear-gradient(
    329deg,
    #00ffff 37.86%,
    #3173cc 66.74%,
    #453cb8 78.19%,
    #460f79 102.4%
);

// Theme colors
$primary: $purpleBright;
$secondary: $blueBright;

$primaryGradiant: linear-gradient(
    0deg,
    rgba(97, 0, 255, 0.15) 0%,
    rgba(97, 0, 255, 0.15) 100%
);
$secondaryGradiant: linear-gradient(
    0deg,
    rgba(0, 125, 255, 0.2) 0%,
    rgba(0, 125, 255, 0.2) 100%
);

$backgroundGrey: $gray6;

$danger: $red;
$warning: $yellow;
$success: $green2;
$light: $gray6;

$warningGradiant: linear-gradient(
    0deg,
    rgba(255, 206, 31, 0.15) 0%,
    rgba(255, 206, 31, 0.15) 100%
);
$successGradiant: linear-gradient(
    0deg,
    rgba(0, 201, 129, 0.1) 0%,
    rgba(0, 201, 129, 0.1) 100%
);
$dangerGradiant: linear-gradient(
    0deg,
    rgba(235, 87, 87, 0.15) 0%,
    rgba(235, 87, 87, 0.15) 100%
);

$link-color: $secondary;
$text-muted: $gray3;

/**********
 * Values *
 **********/
// Width
$featureLayoutLeftWidthMobile: 100%;
$featureLayoutLeftWidthDesktop: 420px;
// Z-Index
$lowestZIndex: -600;
$lowerZIndex: -400;
$lowZIndex: -200;
$regularZIndex: 0;
$highZIndex: 200;
$higherZIndex: 400;
$highestZIndex: 600;

// padding
$spacer: 20px;
$size1: $spacer * 0.25; // 5px
$size2: $spacer * 0.5; // 10px
$size3: $spacer * 0.75; // 15px
$size4: $spacer;
$size5: $spacer * 1.5; // 30px
$size6: $spacer * 2; // 40px
$size7: $spacer * 2.5; // 50px
$contentPadding: calc($spacer/2);
$contentPaddingLg: $spacer;
// Misc. styles
$librexBorderRadius: 10px;
$librexDropshadow: drop-shadow(0px 2px 10px rgba(126, 139, 159, 0.2));
$librexBoxShadow: 0px 2px 10px rgba(126, 139, 159, 0.2);
$border-color: $gray5;

// Variable calculatations
$mainLayoutPadding: calc(2 * $contentPadding);
$mainLayoutPaddingLg: calc(2 * $contentPaddingLg);

$contentListWidth: 420px;

//component height
$mobileHeaderHeight: 50px;

$prefix: "librex-";

// We want the line height to be 20px as per design
// Our base font size is 14px, 20/14 is not a clean number
// So pass in the calc function to get the correct value
$line-height-base: calc(20/14);

@import "bootstrap/scss/variables";
@import "bootstrap/scss/_variables-dark";
@import "bootstrap/scss/mixins";
