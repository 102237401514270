@use "../../../assets/scss/v3/variables" as variables;

.filters {
    display: flex;
    flex-wrap: wrap;

    button {
        border: 1px solid transparent;
        background-color: rgba(131, 139, 172, 0.10);
        color: variables.$gray3;
        border-radius: variables.$librexBorderRadius;
        margin: 0.5rem 0.25rem;
        padding: variables.$size1 variables.$size2;
        text-transform: capitalize;

        &:hover {
            background-color: variables.$primary;
            border-color: variables.$primary;
            color: variables.$white;
            cursor: pointer;
        }
    }

    .active {
        background: variables.$primaryGradiant;
        color: variables.$primary;
    }
}