@use "../../assets/scss/v3/variables" as vars;
.searchDropdown {
    .dropdownItem {
        &:hover {
            background: vars.$primary;
            button {
                color: vars.$white;
            }
        }
    }
}