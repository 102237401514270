@use "../../../assets/scss/v3/variables" as variables;

.label,
.label[class~=form-label] {
    margin: 0px;
    width: 100%;
}
.query {
    &:not(:disabled):not(.disabled):not([readonly]) {
        /** This force overrides bootstrap styles without needing to define everything twice. **/
        &,
        &[class~=form-control] {
            background-color: variables.$white;
            border-radius: variables.$size1;
        }
    }
}