@use "../../../assets/scss/v3/variables" as variables;

.pagination {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: variables.$size2;

    color: variables.$gray3;
    gap: variables.$size2;

    p {
        margin: 0px;
    }

    button:disabled {
        visibility: hidden;
    }

}